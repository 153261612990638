
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { spaceServiceClient, uiServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { UpdateSpaceRequest } from "zaehlerfreunde-central/space_service_pb";
import { toTreeSelectionNode, TreeSelectionNode } from "@/utils/proto-utils";
import {
  GetTreeSelectRequest,
  GetTreeSelectResponse,
  SpaceTreeSelectRequest,
} from "zaehlerfreunde-central/ui_service_pb";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import Treeselect from "@riophae/vue-treeselect";
import { ASYNC_SEARCH } from "@riophae/vue-treeselect";

@Component({
  components: { Treeselect },
})
export default class ChangeAdminChildSpaces extends Vue {
  @Prop({ default: false }) value: boolean;
  @Prop() space: Space;
  @partnerModule.State partner: RemoteData<UserError, Partner>;
  updateChildSpacesCall: RemoteCall<UserError> = initialized;

  childSpacesTreeSelect: RemoteData<UserError, GetTreeSelectResponse.Node[]> = initialized;
  selectedChildSpaceNodes: TreeSelectionNode[] = [];

  openDialog: boolean = false;

  @Watch("value")
  async openDialogFunction(): Promise<void> {
    this.openDialog = this.value;

    if (this.value) {
      this.selectedChildSpaceNodes = [];
      this.childSpacesTreeSelect = initialized;

      await this.loadSpacesTree();
      let treeSelectOptions: TreeSelectionNode[] =
        this.childSpacesTreeSelect?.list?.length > 0
          ? this.childSpacesTreeSelect?.list?.map((node) => toTreeSelectionNode(node))
          : [];

      treeSelectOptions.map((node) => this.fetchSelectedOptions(node));
    }
  }

  async loadOptions({ action, searchQuery, callback }) {
    if (action === ASYNC_SEARCH) {
      await this.loadSpacesTree(searchQuery);

      let treeSelectOptions: TreeSelectionNode[] =
        this.childSpacesTreeSelect?.list?.length > 0
          ? this.childSpacesTreeSelect?.list?.map((node) => toTreeSelectionNode(node))
          : [];

      callback(null, treeSelectOptions);
    } else {
      callback(null, null);
    }
  }

  get title(): string {
    return "Untergeordnete Objekte anpassen - " + this.space.getName();
  }

  async loadSpacesTree(searchQuery?: string): Promise<void> {
    const request = new GetTreeSelectRequest();
    const spaceTreeSelectRequest = new SpaceTreeSelectRequest();

    spaceTreeSelectRequest.setPartnerId(this.partner.data?.getId() ?? "");
    spaceTreeSelectRequest.setSpaceId(this.space.getId() ?? "");

    if (searchQuery) {
      spaceTreeSelectRequest.setSearchQuery(searchQuery);
    }
    request.setSpaceRequest(spaceTreeSelectRequest);

    try {
      this.childSpacesTreeSelect = pending;
      const response = await uiServiceClient.getTreeSelect(request, {});
      if (response) {
        this.childSpacesTreeSelect = success(response.getNodesList());
      }
    } catch (error) {
      this.childSpacesTreeSelect = failure(userErrorFrom(error));
    }
  }

  fetchSelectedOptions(node: TreeSelectionNode) {
    if (node.id === this.space.getId()) {
      node.children?.map((node) => {
        if (!this.selectedChildSpaceNodes.some((n) => n.id === node.id)) {
          this.selectedChildSpaceNodes.push(node);
        }
      });
    } else {
      node.children?.map((node) => this.fetchSelectedOptions(node));
    }
  }
  async onSaveClicked(): Promise<void> {
    this.updateChildSpacesCall = pending;

    if (this.space?.getId()) {
      const request = new UpdateSpaceRequest();
      request.setSpaceId(this.space?.getId());

      const childSpaces = new UpdateSpaceRequest.ChildSpaces();
      childSpaces.setChildSpaceIdsList(this.selectedChildSpaceNodes.map((node) => node.id));
      request.setParentSpaceId(this.space.getParentSpaceId());
      request.setChildSpaces(childSpaces);

      try {
        await spaceServiceClient.updateSpace(request, {});
        this.updateChildSpacesCall = success(void 0);
        this.$emit("spaceUpdated");
        this.$emit("input", false);
      } catch (error) {
        this.updateChildSpacesCall = failure(userErrorFrom(error));
      }
    }
  }
}
