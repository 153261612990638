
import spaces from "@/store/modules/spaces";
import { failure, initialized, pending, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { deviceServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";
import { GetTeslaAuthURLRequest, GetTeslaAuthURLResponse } from "zaehlerfreunde-central/device_service_pb";

export interface TeslaConnectionState {
  spaceId: string;
  deviceName: string;
  isMainDevice: boolean;
  isAdminAction: boolean;
}

@Component
export default class TeslaConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;
  authUrl: RemoteData<UserError, GetTeslaAuthURLResponse> = initialized;

  onCloseButtonClicked(): void {
    this.$router.go(-1);
  }

  mounted(): void {
    this.fetchTeslaAuthUrls();
  }

  goToTeslaPortal(): void {
    if (this.authUrl.data) {
      window.open(this.authUrl.data.getAuthUrl(), "_self");
    }
  }

  async fetchTeslaAuthUrls(): Promise<void> {
    try {
      this.authUrl = pending;
      const state: TeslaConnectionState = {
        spaceId: this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId,
        deviceName: this.generalInfo.deviceName,
        isMainDevice: this.generalInfo.isMainDevice,
        isAdminAction: this.adminAction,
      };

      const request = new GetTeslaAuthURLRequest();
      request.setState(JSON.stringify(state));
      const response = await deviceServiceClient.getTeslaAuthURL(request, {});
      this.authUrl = success(response);
    } catch (error) {
      this.authUrl = failure(userErrorFrom(error));
    }
  }
}
