
import { deviceServiceClient } from "@/config/service-clients";
import { failure, initialized, pending, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { GetSolarizeSitesRequest } from "zaehlerfreunde-central/device_service_pb";
import { SolarizeSite } from "zaehlerfreunde-proto-types/device_registration_pb";

export interface SiteInput {
  siteId: string;
  meterSerial: string;
}

@Component
export default class SelectSite extends Vue {
  @Prop() postcode: string;
  @Prop() value: SiteInput | null;
  sites: RemoteData<UserError, SolarizeSite[]> = initialized;

  selectedSiteId: string | null = null;
  meterSerial: string | null = null;

  @Watch("meterSerial")
  onSelectedMeterIdChanged() {
    if (this.selectedSiteId && this.meterSerial) {
      this.$emit("input", {
        siteId: this.selectedSiteId,
        meterSerial: this.meterSerial,
      });
    } else {
      this.$emit("input", null);
    }
  }

  mounted() {
    if (this.value) {
      this.selectedSiteId = this.value?.siteId;
      this.meterSerial = this.value?.meterSerial;
    }

    this.getSites();
  }

  get siteItems() {
    return this.sites.data?.map((s) => ({
      value: s.getId(),
      text: `${s.getName()} (${s.getAddress()?.getAddressLineOne()}, ${s.getAddress()?.getPostcode()} ${s
        .getAddress()
        ?.getCity()})`,
    }));
  }

  async getSites() {
    try {
      this.sites = pending;

      const request = new GetSolarizeSitesRequest();
      request.setPartnerId("ip");
      request.setPostcode(this.postcode);
      const response = await deviceServiceClient.getSolarizeSites(request, {});

      this.sites = success(response.getSitesList());
    } catch (error) {
      this.sites = failure(userErrorFrom(error));
    }
  }
}
