
import { Vue, Component, Prop } from "vue-property-decorator";
import { Device } from "zaehlerfreunde-proto-types/device_pb";

@Component({
  components: {},
})
export default class DeviceName extends Vue {
  @Prop() deviceType: Device.Type;

  name: string = "";

  get placeholder(): string {
    switch (this.deviceType) {
      case (Device.Type.SMART_METER, Device.Type.NON_SMART_METER):
        return "Mein Zähler";
      case Device.Type.OPTICAL_METER_SENSOR:
        return "Mein Auslesekopf";
      case Device.Type.WALLBOX:
        return "Meine Wallbox";
      case Device.Type.HEAT_PUMP:
        return "Meine Wärmepumpe";
      case Device.Type.ENERGY_METER:
        return "Mein Energiemesser";
      case Device.Type.MICRO_INVERTER:
        return "Mein Wechselrichter";
      case Device.Type.SMART_PLUG:
        return "Meine Steckdose";
      case Device.Type.SMART_RELAY:
        return "Mein Relais";
      case Device.Type.VIRTUAL_METER:
        return "Mein Virtuelles Gerät";
      case Device.Type.SMART_CAR:
        return "Mein E-Auto";
      default:
        return "Mein Gerät";
    }
  }

  onContinueClicked(): void {
    if (this.name.length > 0) {
      this.$emit("name-selected", this.name);
    } else {
      this.$emit("name-selected", this.placeholder);
    }
  }
}
