
import { deviceServiceClient, readingsServiceClient } from "@/config/service-clients";
import { consumptionModule } from "@/store/modules/consumption";
import { devicesModule } from "@/store/modules/devices";
import { RemoteCall, RemoteData, failure, initialized, pending, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { dateToDateTime } from "@/utils/proto-utils";
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { ExportDataTypeWithLabel, GetExportDataTypesRequest } from "zaehlerfreunde-central/device_service_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { DeviceReading } from "zaehlerfreunde-proto-types/device_reading_pb";
import { ExportDeviceDataRequest } from "zaehlerfreunde-central/reading_service_pb";
import { spacesModule } from "@/store/modules/spaces";
import { ExportDataType } from "zaehlerfreunde-proto-types/data_export_pb";

@Component
export default class CSVExport extends Vue {
  @Prop({ default: null }) fixedDeviceId: string | null;

  @devicesModule.State devices: RemoteData<UserError, Device[]>;
  @consumptionModule.State deviceIngestionReadingsForCSVExport: RemoteData<UserError, DeviceReading[]>;
  @consumptionModule.State deviceConsumptionReadingsForCSVExport: RemoteData<UserError, DeviceReading[]>;
  @spacesModule.Getter selectedSpaceId: string;

  show: boolean = true;
  startDate: Date | null = null;
  endDate: Date | null = null;
  selectedDeviceId: string | null = null;
  resolution: number | null;
  availableDataTypes: RemoteData<UserError, ExportDataTypeWithLabel[]> = initialized;
  filteredDataTypes: ExportDataTypeWithLabel[] = [];
  selectedDataTypes: ExportDataType[] = [];

  exportCSVCall: RemoteCall<UserError> = initialized;

  resolutions: { text: string; value: number }[] = [
    { text: "15 min", value: 15 },
    { text: "1 Stunde", value: 60 },
    { text: "1 Tag", value: 60 * 24 },
  ];

  mounted() {
    if (this.fixedDeviceId) {
      this.selectedDeviceId = this.fixedDeviceId;
      this.getAvailableMeasurements(this.selectedDeviceId);
    }
  }

  @Watch("startDate")
  @Watch("endDate")
  resolutionChecker(): void {
    if (this.endDate && this.startDate) {
      const start: Date = this.startDate instanceof Date ? this.startDate : new Date(this.startDate);
      const end: Date = this.endDate instanceof Date ? this.endDate : new Date(this.endDate);
      const diffTime = Math.abs(end.getTime() - start.getTime());
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays > 30) {
        this.resolutions = [{ text: "1 Tag", value: 60 * 24 }];
        this.resolution = 60 * 24;
      } else {
        this.resolutions = [
          { text: "15 min", value: 15 },
          { text: "1 Stunde", value: 60 },
          { text: "1 Tag", value: 60 * 24 },
        ];
      }
    }
  }

  get deviceItems() {
    return (
      this.devices.data?.map((d) => ({
        text: d.getName(),
        value: d.getId(),
      })) ?? []
    );
  }

  hideDialog(): void {
    this.show = false;
    this.$emit("closed");
  }

  getLabel(type: ExportDataTypeWithLabel): string {
    return type.getLabel();
  }

  onDeviceSelected(deviceId: string): void {
    this.selectedDataTypes = [];
    this.getAvailableMeasurements(deviceId);
    this.selectedDeviceId = deviceId;
  }

  onSelectionChanged(): void {
    if (this.selectedDataTypes.includes(ExportDataType.EXPORT_THROUGHPUT_BIDIRECTIONAL)) {
      this.filteredDataTypes = this.filteredDataTypes.filter(
        (t) => t.getType() == ExportDataType.EXPORT_THROUGHPUT_BIDIRECTIONAL
      );
    } else if (
      this.selectedDataTypes.includes(ExportDataType.EXPORT_TOTALS_CONSUMPTION) ||
      this.selectedDataTypes.includes(ExportDataType.EXPORT_TOTALS_INGESTION)
    ) {
      this.filteredDataTypes = this.filteredDataTypes.filter(
        (t) =>
          t.getType() == ExportDataType.EXPORT_TOTALS_CONSUMPTION ||
          t.getType() == ExportDataType.EXPORT_TOTALS_INGESTION
      );
    } else if (this.selectedDataTypes.length == 0) {
      this.filteredDataTypes = this.availableDataTypes.list;
    } else {
      this.filteredDataTypes = this.filteredDataTypes.filter(
        (t) =>
          t.getType() == ExportDataType.EXPORT_AMOUNTS_CONSUMPTION ||
          t.getType() == ExportDataType.EXPORT_AMOUNTS_INGESTION ||
          t.getType() == ExportDataType.EXPORT_COSTS ||
          t.getType() == ExportDataType.EXPORT_EARNINGS ||
          t.getType() == ExportDataType.EXPORT_CO2
      );
    }
  }

  async getAvailableMeasurements(deviceId: string): Promise<void> {
    this.availableDataTypes = pending;
    const request = new GetExportDataTypesRequest();
    request.setDeviceId(deviceId);
    try {
      const response = await deviceServiceClient.getExportDataTypes(request, {});
      this.availableDataTypes = success(response.getAvailableTypesList());
      this.filteredDataTypes = response.getAvailableTypesList();
    } catch (error) {
      this.availableDataTypes = failure(userErrorFrom(error));
    }
  }

  async downloadData(): Promise<void> {
    const request = new ExportDeviceDataRequest();
    if (
      this.selectedDeviceId &&
      this.startDate &&
      this.endDate &&
      this.resolution &&
      this.selectedDataTypes.length != 0
    ) {
      try {
        this.exportCSVCall = pending;
        request.setDeviceId(this.selectedDeviceId);
        request.setSpaceId(this.selectedSpaceId);
        request.setStartTime(dateToDateTime(this.startDate));
        request.setEndTime(dateToDateTime(this.endDate).setHours(24));
        request.setResolution(this.resolution);
        request.setExportTypesList(this.selectedDataTypes);

        const response = await readingsServiceClient.exportDeviceData(request, {});

        this.exportCSVCall = success(void 0);

        const blob = new Blob([response.getContent()], { type: "text/csv;charset=utf-8," });
        const objUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", objUrl);
        link.setAttribute("download", response.getFileName());
        link.click();
      } catch (error) {
        this.exportCSVCall = failure(userErrorFrom(error));
      }
    }
  }

  requiredRule(input: string): string | boolean {
    return input.length > 0 ? true : "Dieses Feld ist erforderlich";
  }
}
