
import { subscriptionModule } from "@/store/modules/subscription";
import { initialized, RemoteCall, RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import SubscriptionPlans from "@/components/subscription/SubscriptionPlans.vue";
import { devicesModule } from "@/store/modules/devices";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";

@Component({
  components: {
    SubscriptionPlans,
  },
})
export default class Subscription extends Vue {
  @subscriptionModule.State createCheckoutSessionCall: RemoteCall<UserError>;
  cancelProSubscriptionCall: RemoteCall<UserError> = initialized;
  @partnerModule.State partner: RemoteData<UserError, Partner>;
  @devicesModule.State devices: RemoteData<UserError, Device[]>;
  @Prop({ default: true }) isSignedIn: boolean;

  DeviceProvider = Device.Provider;

  get requiredFeature(): string | null {
    const requiredFeature = this.$route.query["requiredFeature"];
    if (typeof requiredFeature === "string") {
      return requiredFeature;
    } else if (requiredFeature) {
      return requiredFeature[0];
    } else {
      return null;
    }
  }
}
