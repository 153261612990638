
import { deviceServiceClient } from "@/config/service-clients";
import spaces from "@/store/modules/spaces";
import { pending, success, failure, initialized, RemoteData } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import { RegisterDeviceRequest, RegisterDeviceResponse } from "zaehlerfreunde-central/device_service_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { DeviceRegistration, EdaRegistrationDetails } from "zaehlerfreunde-proto-types/device_registration_pb";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";

@Component
export default class EdaConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;

  registerDeviceCall: RemoteData<UserError, RegisterDeviceResponse> = initialized;
  meteringPoint: string = "";

  meteringPointRules = [
    (mp: string) => (mp.startsWith("AT") ? true : "Zählpunktnummer muss mit AT beginnen."),
    (mp: string) => (mp.length === 33 ? true : "Zählpunktnummer muss 33 Zeichen lang sein."),
  ];

  get isMeteringPointValid(): boolean {
    return this.meteringPointRules.every((r) => r(this.meteringPoint) === true);
  }

  async onNextClicked(): Promise<void> {
    const registration = new DeviceRegistration();
    registration.setDeviceName(this.generalInfo.deviceName);
    registration.setDeviceMedium(Device.Medium.ELECTRICITY);
    registration.setDeviceType(this.generalInfo.deviceType);
    registration.setDeviceProvider(this.generalInfo.deviceProvider);

    const spaceMapping = new DeviceRegistration.SpaceMapping();
    spaceMapping.setSpaceId(this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId);
    spaceMapping.setIsMainDevice(this.generalInfo.isMainDevice);
    registration.setSpaceMapping(spaceMapping);

    const details = new EdaRegistrationDetails();
    details.setMeteringPoint(this.meteringPoint);
    registration.setEda(details);

    const request = new RegisterDeviceRequest();
    request.setRegistration(registration);
    this.registerDeviceCall = pending;
    try {
      const response = await deviceServiceClient.registerDevice(request, {});
      this.registerDeviceCall = success(response);
    } catch (error) {
      this.registerDeviceCall = failure(userErrorFrom(error));
    }
  }
}
