// source: types/features.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.types.Feature', null, global);
/**
 * @enum {number}
 */
proto.types.Feature = {
  AUTOMATIONS: 0,
  SIMULATOR: 1,
  TARIFF_SWITCH: 2,
  TIPS: 3,
  SHOP: 4,
  ENERGY_TARIFF: 5,
  WATER_TARIFF: 6,
  GAS_TARIFF: 7,
  LATEST_STATUS: 8,
  CURRENT_MONTH_OVERVIEW: 9,
  SPACE_COMPARISON: 10,
  INVOICES: 11,
  DEVICES_CONFIG: 12,
  SPACE_CONFIG: 13,
  MARKET_PREMIUM: 14,
  VIRTUAL_SUM_METER: 15,
  SPACE_KPIS: 16,
  CHILD_PARTNER_CREATION: 17,
  REPORTING: 18,
  LANDLORD_TO_TENANT_COST: 19,
  REFERENCE_VALUES: 20,
  EDIT_TARIFF_DETAILS: 21,
  TARIFF_ADVANCE_PAYMENTS: 22,
  ONE_SPACE: 23,
  UP_TO_TWO_SPACES: 24,
  UNLIMITED_SPACES: 25,
  ONE_DEVICE: 26,
  UNLIMITED_DEVICES: 27,
  CSV_EXPORT: 28,
  CHILD_SPACES: 29,
  CONSUMPTION_VISUALISATION: 30,
  POWERFOX: 31,
  CREATE_INVITES: 32,
  COST_CALCULATION: 33,
  CONSUMPTION_TARGET: 34,
  CONNECT_TIBBER_DEVICES: 35,
  COMMERCIAL_SPACES: 36,
  SUPPORT_48H: 37,
  SMART_AND_RLM_METER: 38,
  SUPPORT_3D: 39
};

goog.object.extend(exports, proto.types);
