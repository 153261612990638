
import { deviceServiceClient } from "@/config/service-clients";
import spaces from "@/store/modules/spaces";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { GetMeteringOperatorsRequest, RegisterDeviceRequest } from "zaehlerfreunde-central/device_service_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import {
  DeviceRegistration,
  EsaRegistrationDetails,
  MeteringLocationIdType,
  MeteringOperator,
} from "zaehlerfreunde-proto-types/device_registration_pb";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";

@Component
export default class EsaConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;

  LocationIdType = MeteringLocationIdType;

  registrationCall: RemoteCall<UserError> = initialized;
  meteringOperatorsList: RemoteData<UserError, MeteringOperator[]> = initialized;
  locationIdType: MeteringLocationIdType = MeteringLocationIdType.MEASUREMENT;
  locationId: string = "";
  medium: Device.Medium = Device.Medium.ELECTRICITY;
  searchInput: string = "";
  selectedOperator: MeteringOperator | null = null;

  name(operator: MeteringOperator | null): string {
    return operator?.getName() ?? "";
  }

  bdewCode(operator: MeteringOperator | null): string {
    return operator?.getBdewCode() ?? "";
  }

  sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  @Watch("searchInput")
  async onSearchChanged(): Promise<void> {
    if (this.searchInput != "") {
      const searchTerm = this.searchInput;
      await this.sleep(500);
      if (searchTerm === this.searchInput) {
        this.getMeteringOperators(this.searchInput);
      }
    }
  }

  async getMeteringOperators(filter: string): Promise<void> {
    const request = new GetMeteringOperatorsRequest();
    request.setFilter(filter);

    this.meteringOperatorsList = pending;

    try {
      const response = await deviceServiceClient.getMeteringOperators(request, {});
      this.meteringOperatorsList = success(response.getMeteringOperatorsList());
    } catch (error) {
      this.meteringOperatorsList = failure(userErrorFrom(error));
    }
  }

  inputCompleted(): boolean {
    if (this.locationIdType === this.LocationIdType.MARKET) {
      return this.locationId.length == 11;
    } else {
      return this.locationId.length == 33;
    }
  }

  onSelection(operator: MeteringOperator): void {
    this.selectedOperator = operator;
  }

  async onContinueClicked(): Promise<void> {
    const registration = new DeviceRegistration();
    registration.setDeviceName(this.generalInfo.deviceName);
    registration.setDeviceMedium(this.medium);
    registration.setDeviceType(this.generalInfo.deviceType);
    registration.setDeviceProvider(this.generalInfo.deviceProvider);

    const spaceMapping = new DeviceRegistration.SpaceMapping();
    spaceMapping.setSpaceId(this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId);
    spaceMapping.setIsMainDevice(this.generalInfo.isMainDevice);
    registration.setSpaceMapping(spaceMapping);

    const details = new EsaRegistrationDetails();
    details.setLocationIdType(this.locationIdType);
    details.setLocationId(this.locationId);
    details.setBdewCode(this.selectedOperator?.getBdewCode() ?? "");

    registration.setEsa(details);

    const request = new RegisterDeviceRequest();
    request.setRegistration(registration);

    this.registrationCall = pending;

    try {
      await deviceServiceClient.registerDevice(request, {});

      this.registrationCall = success(void 0);
      this.$router.go(-1);
    } catch (error) {
      this.registrationCall = failure(userErrorFrom(error));
    }
  }
}
