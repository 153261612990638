
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { deviceServiceClient } from "@/config/service-clients";
import { Vue, Component, Watch } from "vue-property-decorator";
import { GetTeslaVehiclesRequest, RegisterDeviceRequest } from "zaehlerfreunde-central/device_service_pb";
import {
  DeviceRegistration,
  TeslaRegistrationDetails,
  TeslaVehicle,
} from "zaehlerfreunde-proto-types/device_registration_pb";
import { Option } from "@/components/core/funnel/OptionSelection.vue";
import devices, { devicesModule } from "@/store/modules/devices";
import { paths } from "@/router/routes";
import { Device } from "zaehlerfreunde-proto-types/device_pb";

@Component
export default class TeslaVehicles extends Vue {
  @devicesModule.State registerDeviceCall: RemoteCall<UserError>;

  teslaVehicles: RemoteData<UserError, Array<TeslaVehicle>> = initialized;
  keyPairingUrl: string = "";
  teslaVehiclesOptions: Option[] = [];
  vehicleToRegister: TeslaVehicle | null = null;
  isVehicleSelected: boolean = false;
  isKeyPairingClicked: boolean = false;
  isAdminAction: boolean = false;

  @Watch("registerDeviceCall")
  onRegisterDeviceCall(): void {
    if (this.registerDeviceCall.succeeded) {
      this.isAdminAction ? this.$router.push(paths.admin.devices) : this.$router.push(paths.platform.devices);
    }
  }

  registerTeslaVehicle(): void {
    const stateJSON = this.$route.query["state"] as string;
    const state = JSON.parse(stateJSON);
    this.isAdminAction = state.isAdminAction;
    const registration = new DeviceRegistration();
    registration.setDeviceName(state.deviceName);
    registration.setDeviceMedium(Device.Medium.ELECTRICITY);
    registration.setDeviceType(Device.Type.SMART_CAR);
    registration.setDeviceProvider(Device.Provider.TESLA);

    const spaceMapping = new DeviceRegistration.SpaceMapping();
    spaceMapping.setSpaceId(state.spaceId);
    spaceMapping.setIsMainDevice(state.isMainDevice);
    registration.setSpaceMapping(spaceMapping);

    const details = new TeslaRegistrationDetails();
    if (this.vehicleToRegister) details.setVehicle(this.vehicleToRegister);

    registration.setTesla(details);

    const request = new RegisterDeviceRequest();
    request.setRegistration(registration);

    devices.registerDevice(registration);
  }

  onCloseButtonClicked(): void {
    this.$router.go(-1);
  }

  mounted(): void {
    const code = this.$route.query["code"] as string;
    if (code) {
      this.getTeslaVehicles(code);
    }
  }

  async getTeslaVehicles(code: string): Promise<void> {
    try {
      this.teslaVehicles = pending;
      const request = new GetTeslaVehiclesRequest();
      request.setAuthenticationCode(code);

      const response = await deviceServiceClient.getTeslaVehicles(request, {});
      this.teslaVehicles = success(response.getVehiclesList());

      this.teslaVehiclesOptions = this.teslaVehicles.list.map((vehicle: TeslaVehicle) => {
        var option: Option = {
          title: vehicle.getName(),
          subtitle: vehicle.getVehicleTag() + " - " + vehicle.getState(),
          value: vehicle,
          disabled: false,
          icon: "mdi-car-side",
          image: vehicle.getImageUrl(),
        };
        return option;
      });

      this.keyPairingUrl = response.getKeyPairingUrl();
    } catch (error) {
      this.teslaVehicles = failure(userErrorFrom(error));
    }
  }

  onTeslaVehicleSelected(vehicle: Option): void {
    this.isVehicleSelected = true;
    this.vehicleToRegister = vehicle.value as TeslaVehicle;
  }

  goToKeyPairing(): void {
    this.isKeyPairingClicked = true;

    if (this.keyPairingUrl) {
      window.open(this.keyPairingUrl);
    }
  }
}
